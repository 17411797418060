var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{attrs:{"dark":"","loading":_vm.loading,"color":"indigo"},on:{"click":function($event){$event.stopPropagation();_vm.credentials.Sandbox ? _vm.postHITs() : _vm.$refs.dialog.show();}}},[_vm._v(" Post HITs ")]),_c('tutti-dialog',{ref:"dialog",attrs:{"title":"Post HITs in Production mode?","maxWidth":"800","persistent":"","actions":[
            {
                label: 'Proceed',
                color: 'indigo darken-1',
                dark: true,
                onclick: _vm.postHITs
            },
            {
                label: 'Cancel',
                color: 'grey darken-1',
                text: true
            }
        ]},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._v(" You are currently in production mode, which will hire MTurk workers and pay them real money. Are you sure to proceed? ")]},proxy:true}])}),_c('tutti-snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }